import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CONFIG_SHAPE } from '../../state/config/config';

import styles from './Footer.module.scss';

const Footer = ({ config }) => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className={styles.footerContainer}>
      <p className={styles.copyrights}>
        &copy;
        <FormattedMessage
          defaultMessage={`${year} Webshop All rights reserved`}
          id="common.copyrights"
          values={{ year, webShopName: config.websiteName }}
        />
      </p>
    </footer>
  );
};

Footer.displayName = 'Footer';
Footer.propTypes = {
  config: CONFIG_SHAPE,
};

export const mapStateToProps = ({ config }) => ({ config });
export default connect(mapStateToProps)(Footer);
